import bindAll from "lodash.bindall";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import React from "react";
import VM from "scratch-vm";
import { connect } from "react-redux";
import { parseCookies } from "nookies";
import { TEKIE_INTERNAL_ACCESS, BASEURL } from "../lib/constant.js";

import ControlsComponent from "../components/controls/controls.jsx";

class Controls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectId: uuidv4(),
        };
        bindAll(this, ["handleGreenFlagClick", "handleStopAllClick"]);
    }

    componentDidMount() {
        const autoStartFunction = () => {
            const cookies = parseCookies();
            const autoStart = cookies?.autoStart === "true";

            if (autoStart && !this.props.projectRunning) {
                this.props.vm.start();
                this.props.vm.greenFlag();

                setTimeout(autoStartFunction, 1000);
            }
        };

        autoStartFunction();
    }

    componentDidUpdate(prevProps) {
        const cookies = parseCookies();
        const autoStart = cookies?.autoStart === "true";
        if (prevProps.isStarted !== this.props.isStarted) {
            if (autoStart) {
                if (this.props.isStarted) {
                    this.callApiOnStart();
                } else {
                    this.callApiOnStop();
                }
            }
        }
    }

    callApiOnStart(projectId) {
        const cookies = parseCookies();
        let apiUrl = `https://${BASEURL.tekieStage}/api/labInspection/detail`;
        let Authorization = TEKIE_INTERNAL_ACCESS.stage;
        if (process.env.NODE_ENV === "prod") {
            apiUrl = `https://${BASEURL.tekieProd}/api/labInspection/detail`;
            Authorization = TEKIE_INTERNAL_ACCESS.prod;
        }
        const requestOptions = {
            method: "POST",
            body: JSON.stringify({
                divId: parseInt(cookies.divId, 10),
                computerId: cookies.computerId,
                websiteAccessibility: "started",
                labId: cookies.labId,
                startTestTime: Date.now(),
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization,
            },
        };
        fetch(apiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    callApiOnStop(projectId) {
        const cookies = parseCookies();
        console.log("call on  end");
        let apiUrl = `https://${BASEURL.tekieStage}/api/labInspection/detail`;
        let Authorization = TEKIE_INTERNAL_ACCESS.stage;
        if (process.env.NODE_ENV === "prod") {
            apiUrl = `https://${BASEURL.tekieProd}/api/labInspection/detail`;
            Authorization = TEKIE_INTERNAL_ACCESS.prod;
        }
        const requestOptions = {
            method: "POST",
            body: JSON.stringify({
                divId: parseInt(cookies.divId, 10),
                computerId: cookies.computerId,
                websiteAccessibility: "ended",
                labId: cookies.labId,
            }),
            headers: {
                "Content-Type": "application/json",
                Authorization,
            },
        };
        fetch(apiUrl, requestOptions)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
    handleGreenFlagClick(e) {
        e.preventDefault();
        if (e.shiftKey) {
            this.props.vm.setTurboMode(!this.props.turbo);
        } else {
            if (!this.props.isStarted) {
                this.props.vm.start();
            }
            this.props.vm.greenFlag();
        }
    }
    handleStopAllClick(e) {
        e.preventDefault();
        this.props.vm.stopAll();
    }

    render() {
        const {
            vm, // eslint-disable-line no-unused-vars
            isStarted, // eslint-disable-line no-unused-vars
            projectRunning,
            turbo,
            ...props
        } = this.props;
        return (
            <ControlsComponent
                {...props}
                active={projectRunning}
                turbo={turbo}
                onGreenFlagClick={this.handleGreenFlagClick}
                onStopAllClick={this.handleStopAllClick}
            />
        );
    }
}

Controls.propTypes = {
    isStarted: PropTypes.bool.isRequired,
    projectRunning: PropTypes.bool.isRequired,
    turbo: PropTypes.bool.isRequired,
    vm: PropTypes.instanceOf(VM),
};

const mapStateToProps = (state) => ({
    isStarted: state.scratchGui.vmStatus.running,
    projectRunning: state.scratchGui.vmStatus.running,
    turbo: state.scratchGui.vmStatus.turbo,
});
// no-op function to prevent dispatch prop being passed to component
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
