import { parseCookies } from "nookies";
import { BASEURL, TEKIE_INTERNAL_ACCESS } from "./constant";
export default (filename, blob) => {
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    // Use special ms version if available to get it working on Edge.
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
        return;
    }
    const cookies = parseCookies();
    if ("download" in HTMLAnchorElement.prototype) {
        if (filename !== "autoSave") {
            const url = window.URL.createObjectURL(blob);

            downloadLink.href = url;
            downloadLink.download = filename;
            downloadLink.type = blob.type;
            downloadLink.click();
        }

        let apiUrl = `https://${BASEURL.tekieStage}/api/upload-scratch`;
        let Authorization = TEKIE_INTERNAL_ACCESS.stage;
        if (process.env.NODE_ENV === "prod") {
            apiUrl = `https://${BASEURL.tekieProd}/api/upload-scratch`;
            Authorization = TEKIE_INTERNAL_ACCESS.prod;
        }
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64String = reader.result.replace(/^data:.+;base64,/, "");
            const requestOptions = {
                method: "POST",
                body: JSON.stringify({
                    userIds: JSON.parse(cookies.buddyUserIds),
                    isPractice:
                        cookies.blockBasedPracticeId !== "undefined" &&
                        cookies.blockBasedPracticeId
                            ? false
                            : true,
                    blob: base64String,
                    projectId:
                        cookies.blockBasedPracticeId !== "undefined" &&
                        cookies.blockBasedPracticeId
                            ? cookies.blockBasedPracticeId
                            : cookies.codeId,
                }),
                headers: {
                    "Content-Type": "application/json",
                    Authorization,
                },
            };

            fetch(apiUrl, requestOptions);
        };

        window.setTimeout(() => {
            document.body.removeChild(downloadLink);
        }, 1000);
    } else {
        // iOS 12 Safari, open a new page and set href to data-uri
        let popup = window.open("", "_blank");
        const reader = new FileReader();
        reader.onloadend = function () {
            popup.location.href = reader.result;
            popup = null;
        };
        reader.readAsDataURL(blob);
    }
};
