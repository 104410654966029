export const BASEURL = {
    tekieStage: "tekie-backend.uolo.co",
    tekieProd: "tekie-backend.uolo.com",
};

export const TEKIE_INTERNAL_ACCESS = {
    stage: "fTIyCZdzA+Y2hWf84SgudbDFpkNgcIXngXdtGCJvT6s=",
    prod: "GUsjCsATuxFubLYPWMN4OTxMh2sBZQ7YaerTVWiDdy0=",
};

